var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ff"},[_c('header',[_c('section',[_c('h1',[_vm._v("Budget Left")]),_vm._v(" "),_c('span',[_vm._v("$"+_vm._s(_vm.budgetLeft))])]),_vm._v(" "),_c('section',[_c('h1',[_vm._v("League Budget Left")]),_vm._v(" "),_c('span',[_vm._v("$"+_vm._s(_vm.leagueBudget))])]),_vm._v(" "),_c('section',[_c('h1',[_vm._v("Inflation")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.positiveInflation)+"%")])]),_vm._v(" "),_c('section',[_c('h1',[_vm._v("Adjustment")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.inf))])]),_vm._v(" "),_c('section',[_c('h1',[_vm._v("Adjusted Inflation")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.adjustedInflation)+"%")])]),_vm._v(" "),(_vm.showInput)?_c('form',{staticClass:"player-input",on:{"submit":function($event){$event.preventDefault();return _vm.setPrice($event)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",attrs:{"autofocus":"","type":"number","placeholder":"$30"},domProps:{"value":(_vm.inputValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value}}}),_vm._v(" "),_c('button',{attrs:{"type":"button","name":"button"},on:{"click":_vm.setPrice}},[_vm._v("Save")])]):_vm._e()]),_vm._v(" "),_c('section',{staticClass:"ff-flex"},[_c('ol',{staticClass:"ff-list"},_vm._l((_vm.players),function(player){return _c('li',{staticClass:"player",class:{
          'is-drafted': player.drafted,
          'is-bought': player.bought,
          'is-heading': player.heading,
          'is-favorite': player.favorite
        },on:{"click":function($event){return _vm.selectPlayer()}}},[_c('span',{staticClass:"player-name"},[_vm._v(_vm._s(player.player))]),_vm._v(" "),_c('span',{staticClass:"player-price"},[_vm._v("$"+_vm._s(player.price))]),_vm._v(" "),_c('span',{staticClass:"player-purchased is-inflation"},[_vm._v("$"+_vm._s((player.price * _vm.inflation.toFixed(2))))]),_vm._v(" "),_c('span',{staticClass:"player-purchased"},[_vm._v("$"+_vm._s(player.purchasePrice))]),_vm._v(" "),_c('span',{staticClass:"player-lift",class:{
            'is-negative': player.price - player.purchasePrice < 0,
            'is-positive': player.price - player.purchasePrice > 0
            }},[_vm._v("\n          "+_vm._s(player.purchasePrice - player.price)+"\n        ")]),_vm._v(" "),_c('button',{attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.playerDrafted(player)}}},[_vm._v("Drafted")]),_vm._v(" "),_c('button',{attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.playerBought(player)}}},[_vm._v("Bought")])])}),0),_vm._v(" "),_c('div',{staticClass:"ff-sidebar"},[_c('section',{staticClass:"ss"},[_c('h1',[_vm._v("My Roster")]),_vm._v(" "),_c('ol',_vm._l((_vm.roster),function(player){return _c('li',{staticClass:"r-player"},[_c('span',[_vm._v(_vm._s(player.player))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(player.purchasePrice))]),_vm._v(" "),_c('button',{on:{"click":function($event){return _vm.removePlayer(player)}}},[_vm._v("X")])])}),0)])])])])}
var staticRenderFns = []
export { render, staticRenderFns }