import Vue from 'vue'
import Vuex from 'vuex'
import data from './data/data'
import { DEFAULT_OPTION } from './data/helpers'

Vue.use(Vuex)

const state = {
  filters: {},
  hover_active: false,
  z: 0,
  highlight: {
    'products': false,
    'info': false,
    'photo_icon': false
  },
  navigated: false,
  options: {},
  activeOption: DEFAULT_OPTION,
}

let createId = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

const mutations = {
  toggleFilter(state, filter) {
    state.filters[filter.type][filter.id].active = !state.filters[filter.type][filter.id].active
    // if(state.filters.includes(filter)) {
    //   const index = state.filters.indexOf(filter)
    //   state.filters.splice(index, 1)
    // } else {
    //   state.filters.push(filter)
    // }
  },
  toggleCategory(state, category) {
    let o = state.filters[category]
    for (const prop in o) {
      o[prop].active = !o[prop].active
    }
  },
  categoryOn(state, category) {
    let o = state.filters[category]
    for (const prop in o) {
      o[prop].active = true
    }
  },
  populateFilters(state, filters) {
    let cat = {}
    data.forEach((item) => {
      let id = item.title.replace(/[!@#$%^&*(),.?":{}|<> ]/g,'').toLowerCase()
      if (!cat[item.type]) cat[item.type] = {}
      cat[item.type][id] = item
      item.id = id
    })
    state.filters = cat
  },
  bumpZ (state) {
    state.z++
  },
  activateHover(state) {
    state.hover_active = true
  },
  deActivateHover(state) {
    state.hover_active = false
  },
  highlight(state, item) {
    state.highlight[item] = true
  },
  unHighlight(state, item) {
    state.highlight[item] = false
  },
  navigated(state) {
    state.navigated = true
  },
  // Option State
  addOption(state, option) {
    let id = createId()
    option.id = id
    state.options = { ...state.options, [id]: option}
  },
  editOption(state, option) {
    const id = option.id
    state.options = { ...state.options, [id]: option}
  },
  activateOption(state, option) {
    state.activeOption = option
  },
  addAndActivate(state) {
    let id = createId()
    let option = JSON.parse(JSON.stringify(DEFAULT_OPTION))
    option.id = id
    option.premium = option.premium + 1
    state.options = { ...state.options, [id]: option}
    state.activeOption = option
  }
}

const getters = {
  filters: state => {
    return state.filters
  },
  options: state => {
    return state.options
  },
  option: state => id => {
    return state.options[id]
  },
  optionLength: state => {
    return Object.keys(state.options).length
  },
  getFirstOption: state => {
    return Object.keys(state.options)[0]
  },
  getActiveOption: state => {
    return state.activeOption
  },
  getWinningValue: state => {
    return state.winningValue
  }
}

export default new Vuex.Store({
  state,
  mutations,
  getters
})
