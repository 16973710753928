//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import * as d3 from 'd3'
import {TweenMax, RoughEase, Expo, Elastic, TimelineMax} from "gsap/TweenMax"
import CountUp from 'countup'
const API_LOC = 'https://sheets.googleapis.com/v4/spreadsheets'

export default {
  name: 'DataIsB',
  data () {
    return {
      content: {
        api_key: 'AIzaSyCSzAoO5nTisHeBhU22Uj6OLFiv0q8FvzE',
        sheet_id: '151PdE8-dW0V0raOYZdlLjtRJAGb4Pferz5QUo68GL5E',
        sheet_name: 'standings',
        headings: 'A1:M1',
        cells: 'A2:M33'
      },
      headings: [],
      data: [],
      xScale: null,
      yScale: null,
      selected_x: {
        label: null,
        value: null
      },
      selected_y: {
        label: null,
        value: null
      }
    }
  },
  computed: {
    headingPreview () {
      if (!this.headings) return 'N/A'
      let headings = '('
      for (var i=0; i<3; i++) {
        headings += `${this.headings[i]}, `
      }
      headings += '...)'
      return headings
    }
  },
  methods: {
    changeX (e) {
      this.selected_x.value = this.headings.indexOf(this.selected_x.label);
    },
    changeY (e) {
      this.selected_y.value = this.headings.indexOf(this.selected_y.label);
    },
    xValue (d, data) {
      if (d[data] === NaN) return 0
      return d[data]
    },
    yValue (d, data) {
      return d[data]
    },
    setScale (data) {
      if(!this.xScale || !this.yScale) return
      let pullX = (d) => { return d[data.x.value]}
      let pullY = (d) => { return d[data.y.value]}
      this.xScale.domain([d3.min(this.data, pullX), d3.max(this.data, pullX)])
      this.yScale.domain([d3.min(this.data, pullY), d3.max(this.data, pullY)])
    },
    buildChart (container, data) {
      let self = this
      this.svg = null
      let margin = {
            top: 20,
            right: 200,
            bottom: 30,
            left: 80
          },
          width = 830 - margin.left - margin.right,
          height = 500 - margin.top - margin.bottom

      this.xScale = d3.scaleLinear().range([0, width])
      this.yScale = d3.scaleLinear().range([height, 0])

      let xAxis = d3.axisBottom().scale(this.xScale),
          yAxis = d3.axisLeft().scale(this.yScale)

      let xMap = (d) => {
        let val = this.xScale(self.xValue(d, data.x.value))
        if (val === NaN) return 0
        return val
      }

      let yMap = (d) => {
        let val = this.yScale(self.yValue(d, data.y.value))
        if (val === NaN) return 0
        return val
      }

      // setup fill color
      let cValue = function(d) { return d[0]},
          color = d3.scaleOrdinal(d3.schemeDark2)

      // add the graph canvas to the body of the webpage
      d3.select(`${container} svg`).remove()
      this.svg = d3.select(container).append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

      // add the tooltip area to the webpage
      let tooltip = d3.select(container).append("div")
          .attr("class", "tooltip")
          .style("opacity", 0)

      this.setScale(data)

      // x-axis
      this.svg.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
          .call(xAxis)
        .append("text")
          .attr("class", "label")
          .attr("x", width)
          .attr("fill", "white")
          .attr("y", -6)
          .style("text-anchor", "end")
          .text(self.selected_x.label)

      // y-axis
      this.svg.append("g")
          .attr("class", "y axis")
          .call(yAxis)
        .append("text")
          .attr("class", "label")
          .attr("fill", "white")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", ".71em")
          .style("text-anchor", "end")
          .text(self.selected_y.label);

      // draw dots
      this.svg.selectAll(".dot")
          .data(this.data)
        .enter().append("circle")
          .attr("class", "dot")
          .attr("r", 3.5)
          .attr("cx", xMap)
          .attr("cy", yMap)
          .style("fill", "#403434")

     this.svg.selectAll(".text")
       .data(this.data)
       .enter().append("text")
       .attr("class", "dot-label")
       .text(function(d) {
           return d[0]
       })
       .attr("x", xMap)
       .attr("y", yMap)
    },
    get (selection, callback) {
      axios.get(`${API_LOC}/${this.content.sheet_id}/values/${selection}${`?key=${this.content.api_key}`}`)
      .then((response) => {
        if (callback) return callback(response.data.values)
        return response.data.values
      })
      .catch((error) => {
        console.log(error)
      })
    },
    getData (callback) {
      const SHEET_SELECTION = `${this.content.sheet_name}!${this.content.cells}`
      this.get(SHEET_SELECTION, this.buildCharts)
    },
    buildCharts (data) {
      this.data = data
      this.buildChart(".chart-1", { x: this.selected_x, y: this.selected_y })
    },
    populateHeadings (data) {
      this.headings = data[0]
    },
    getHeadings () {
      this.get(`${this.content.sheet_name}!${this.content.headings}`, this.populateHeadings)
    }
  },
  created () {
    this.getHeadings()
  }
}
