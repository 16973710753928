//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterCheck from './FilterCheck'

export default {
  name: 'CategoryFilter',
  props: [
    'item'
  ],
  components: {
    FilterCheck
  },
  data () {
    return {
      collapsed: false,
    }
  },
  methods: {
    toggleCategory () {
      this.collapsed = !this.collapsed;
    }
  },
  computed: {
    filters () {
      return this.$store.getters.filters
    },
    categoryStatus () {
      let o = this.filters[this.item.param]
      let falseCount = 0
      let trueCount = 0
      let length = 0;
      for (const prop in o) {
        if (o[prop].active) trueCount++
        if (!o[prop].active) falseCount++
        length++
      }
      if (falseCount === 0) return 'active'
      if (trueCount > 0 && trueCount< length) return 'partial'
      else return 'inactive'
    }
  },
  mounted() {
    // this.activeFilter()
  }
}
