//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const MS_IN_YEAR = 1000 * 60 * 60 * 24 * 365.25
const TOTAL_YEARS = 90
const BILLION = 1000000000

export default {
  name: 'timeleft',
  data () {
    return {
      birthday: '1990-04-15',
      showEllapsed: true,
      unit: 'years'
    }
  },
  methods: {
    getDiffFromEnd () {

    }
  },
  computed: {
    difference () {
      if (!this.birthday) return 'enter bdy'
      let today = new Date()
      let bday = new Date(this.birthday)
      return today - bday
    },
    years () {
      return this.difference / MS_IN_YEAR
    },
    yearsLeft () {
      let totalY = new Date(TOTAL_YEARS)
      return totalY - this.years
    },
    secondsLeft () {
      let s = this.yearsLeft * MS_IN_YEAR / 1000
      if (s > BILLION) {
        return `You're a billionaire, ${s/BILLION} billion seconds left`
      }
    },
    ellapsed () {
      let arr = []
      let length = this.years
      for (var i = 0; i < length; i++) {
        arr.push(i)
      }
      return arr
    },
    weeksLeft () {
      let arr = []
      let length = this.yearsLeft
      for (var i = 0; i < length; i++) {
        arr.push(i)
      }
      return arr
    }
  }
}
