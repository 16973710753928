//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import Viz from 'viz.js'
const GRAPH_DEFAULT = 'digraph "x"{\n node [shape=rectangle]; \n  1->2\n  5->2;\n  1->3;\n  3->4;\n  4->1\n}'

export default {
  name: 'state-machine',
  data () {
    return {
      error: '',
      dots: null,
      shareLink: null
    }
  },
  mounted () {
    if (this.$route.query.dot) {
      this.dots = decodeURIComponent(this.$route.query.dot)
    } else if (this.$ls.get('dots')) {
      this.dots = this.$ls.get('dots')
    } else {
      this.dots = GRAPH_DEFAULT
    }
  },
  computed: {
    textarea () {
      return this.$refs['text']
    },
    image () {
      this.error = ''
      try {
        return Viz(this.dots, {format: 'svg'})
      }
      catch (error) {
        this.error = 'Waiting for Valid Graph'
      }
    }
  },
  methods: {
    share () {
      let comp = encodeURIComponent(this.dots)
      this.shareLink = `${window.location}?dot=${comp}`
    },
    handler (event) {
      this.$ls.set('dots', this.dots)
    },
    keyHandler (event) {
      console.log(event.key)
      event.preventDefault()
      if (!event.key) return
      if (event.key === "Escape") return this.textarea.blur()
      if (event.key === "Tab") return '  '
    }
  }
}
