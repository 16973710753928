//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'

export default {
  name: 'OS',
  data () {
    return {
      values: [],
      name: '',
      value: 0,
      over_active: false
    }
  },
  methods: {
    create () {
      this.values.push({
        'name': this.name,
        'value': parseInt(this.value) || 0
      })
      this.$ma.trackEvent({action: 'Added Block', properties: {'Name': this.name}}, ['ga'])
      this.name = ''
      this.value = 0
      this.$refs['input'].focus()
    }
  },
  computed: {
    total () {
      let count = 0
      _.forEach(this.values, (item) => {
        count += item.value
      })
      return count
    }
  }
}
