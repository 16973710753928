//
//
//
//
//
//

import Foot from './Foot.vue'

export default {
  name: 'Author',
  props: [
    'home'
  ],
  components: {
    Foot
  },
  data () {
    return {
    }
  },
  methods: {
    highlight(item) {
      this.$store.commit('highlight', item)
    },
    unHighlight(item) {
      this.$store.commit('unHighlight', item)
    },
    goToWork(item) {
      this.$store.commit('unHighlight', item)
      this.$store.commit('populateFilters', ['image', 'guide', 'article'])
      let query = this.$store.state.filters.join(',')
      this.$router.push({ query: { sort: query } })
      this.$ma.trackEvent({action: 'Go to Work', properties: {'Filter': query}}, ['ga'])
    },
    filter(items) {
      this.$store.commit('populateFilters', items)
      let query = this.$store.state.filters.join(',')
      this.$router.push({name: 'home',  query: { sort: query }})
      this.$ma.trackEvent({action: 'Selected Bio Link', properties: {'Filter': query}}, ['ga'])
    }
  }
}
