//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import players from '../data/salaries'
import { BlackScholes } from '../data/helpers'

export default {
  name: 'Fantasy',
  data () {
    return {
      players: players,
      roster: {},
      budgetLeft: 225,
      inputValue: 30,
      showInput: false,
      leagueBudget: 225 * 8,
      inf: 0
    }
  },
  methods: {
    selectPlayer (player) {
    },
    playerDrafted (player) {
      player.drafted = true
      player.bought = false
      let key = player.player.trim()
      player.key = key
      this.roster[key] = player
      this.currentPlayer = player
      this.showInput = 'draft'
      this.focus()
    },
    playerBought (player) {
      player.bought = true
      player.drafted = false
      this.currentPlayer = player
      this.showInput = 'bought'
      this.focus()
    },
    focus () {
      setTimeout(() => {
        this.$refs['input'] && this.$refs['input'].focus()
      }, 5)
    },
    setInflation (player) {
      let diff = player.purchasePrice - player.price
      console.log(diff)
      this.inf = this.inf + diff
      console.log(this.inf)
    },
    removePlayer (player) {
      const players = Object.assign({}, this.roster)
      delete players[player.key]
      this.roster = players
    },
    setPrice () {
      if (this.showInput == 'draft') {
        this.budgetLeft = this.budgetLeft - this.inputValue
      }
      this.currentPlayer.purchasePrice = this.inputValue
      this.leagueBudget = this.leagueBudget - this.inputValue
      this.showInput = false
      this.setInflation(this.currentPlayer)
    },
    logKey (e) {
      if (e.key !== "Escape") return
      this.showInput = false
    }
  },
  computed: {
    totalValue () {
      let price = 0
      for (let i = 0; i < this.players.length; i++) {
        let p = this.players[i].price
        price = price + p
      }
      return price
    },
    positiveInflation () {
      return ((this.inflation - 1)*100).toFixed(4)
    },
    inflation () {
      return this.totalValue / this.leagueBudget
    },
    adjustedInflation () {
      if (this.inf < 0)
      return this.inflation
    }
  },
  mounted () {
    document.addEventListener('keyup', this.logKey);
  }
}
