//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'work-list-item',
  props: [
    'item',
    'id',
  ],
  data() {
    return {
      hover: false
    }
  },
  created () {
  },
  methods: {
    navigate () {
      this.$router.push({ name: 'work', params: { id: this.id }})
    },
    activate (event) {
      this.$emit('activate', event, this.item, this.id)
      this.hover = true
    },
    deactivate () {
      this.$emit('deactivate')
      this.hover = false
    }
  },
  computed: {
  }
}
