//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
import * as d3 from 'd3'
import {TweenMax, RoughEase, Expo, Elastic, TimelineMax} from "gsap/TweenMax"
import CountUp from 'countup'

export default {
  name: 'DataIsB',
  data () {
    return {
      api_key: 'AIzaSyCSzAoO5nTisHeBhU22Uj6OLFiv0q8FvzE',
      spreadsheet_id: '1LYxoa0NoyOOfYyKVYEx8fxit7FMd4cMBZacwvorYUnQ',
      data: [],
      scale: 100000,
      containerSize: 300,
      tl: new TimelineMax(),
      selected_1: null,
      selected_2: null,
      xScale: null,
      yScale: null,
      options_1: [
        {
          label: 'Human Development Index',
          value: 6
        },
        {
          label: 'Happy Planet Index',
          value: 5
        },
        {
          label: 'World Happiness Report Score',
          value: 7
        }
      ],
      options_2: [
        {
          label: 'GDP per Capita (PPP)',
          value: 10
        },
        {
          label: 'Health Expenditure (% of GDP)',
          value: 12
        },
        {
          label: 'Education Expenditure (% of GDP)',
          value: 14
        },
        {
          label: '% of GDP Expenditure',
          value: 19
        }
      ],
      container: {
        health: null,
        education: null,
        population: null,
        population_shadow: null,
        main: null
      },
      active: {
        education: 0,
        health: 0,
        population: 0,
        population_shadow: 0
      }
    }
  },
  mounted () {
    this.getContainers()
    this.setDefaults()
    this.tweenTo()
  },
  methods: {
    setDefaults () {
      this.selected_1 = this.options_1[0]
      this.selected_2 = this.options_2[0]
    },
    getContainers () {
      this.container.health = this.$refs['c-health']
      this.container.education = this.$refs['c-education']
      this.container.main = this.$refs['c-container']
      this.container.population = this.$refs['c-population']
      this.container.population_shadow = this.$refs['c-population_shadow']
    },
    checkValue () {
      if (this.selected_1 && this.selected_2) {
        return this.buildChart(".chart-2", { x: this.selected_2.value, y: this.selected_1.value })
      }
    },
    activate (d) {
      const HEALTH_MAX = 9536
      const EDUCATION_MAX = 7466
      const POPULATION_MAX = 1386000000
      this.active.health = (d[13] / HEALTH_MAX)*100+ '%'
      this.active.education = (d[15] / EDUCATION_MAX)*100 + '%'
      this.active.population = (d[2] / POPULATION_MAX)*100
      this.active.population_shadow = (d[2] / POPULATION_MAX)*100
      this.active.health_count = d[13]
      this.active.education_count = d[15]
      this.containerSize = this.containerSize + 15
      if (this.containerSize > 360) this.containerSize = 340
      return this.tweenTo()
    },
    tweenTo () {
      this.tl
      .to(this.container.main, .1, {
        attr: {
          r: this.containerSize
        },
        ease: Elastic.easeOut.config(1, 0.3)
      })
      .to(this.container.population, .3, {
        css: {
          transform: `scale(${this.active.population})`
        },
        ease: Expo.eastOut
      })
      .to(this.container.population_shadow, .1, {
        css: {
          transform: `scale(${this.active.population_shadow})`
        },
        ease: Expo.eastOut
      })
      .to(this.container.education, .2, {
        attr: {
          r: this.active.education
        },
        ease: Expo.eastOut
      })
      .to(this.container.health, .3, {
        attr: {
          r: this.active.health
        },
        ease: Expo.eastOut
      })
    },
    deactivate () {
      this.tl.clear()
      this.tweenTo()
    },
    xValue (d, data) {
      if (d[data] === NaN) return 0
      return d[data]
    },
    yValue (d, data) {
      return d[data]
    },
    setScale (data) {
      if(!this.xScale || !this.yScale) return
      let pullX = (d) => { return d[data.x.value]}
      let pullY = (d) => { return d[data.y.value]}
      this.xScale.domain([d3.min(this.data, pullX), d3.max(this.data, pullX)])
      this.yScale.domain([d3.min(this.data, pullY), d3.max(this.data, pullY)])
    },
    buildChart (container, data) {
      let self = this
      this.svg = null
      let margin = {top: 20, right: 20, bottom: 30, left: 80},
          width = 960 - margin.left - margin.right,
          height = 500 - margin.top - margin.bottom

      this.xScale = d3.scaleLinear().range([0, width])
      this.yScale = d3.scaleLinear().range([height, 0])

      let xAxis = d3.axisBottom().scale(this.xScale),
          yAxis = d3.axisLeft().scale(this.yScale)

      let xMap = (d) => {
        let val = this.xScale(self.xValue(d, data.x.value))
        if (val === NaN) return 0
        return val
      }

      let yMap = (d) => {
        let val = this.yScale(self.yValue(d, data.y.value))
        if (val === NaN) return 0
        return val
      }

        // setup fill color
        let cValue = function(d) { return d[0]},
            color = d3.scaleOrdinal(d3.schemeDark2)

        // add the graph canvas to the body of the webpage
        d3.select(`${container} svg`).remove()
        this.svg = d3.select(container).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
          .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

        let svg_viz = d3.select('.viz-svg')
          .append("g")
            .append("text")
              .attr("class", "viz-country")
              .attr("x", "80%")
              .attr("y", "80%")
              .attr("font-size", "300%")
              .attr("fill", "white")
              .style("text-anchor", "end")

        svg_viz
        .append("g")
          .append("text")
            .attr("class", "viz-population")
            .attr("x", "80%")
            .attr("y", "60%")
            .attr("font-size", "200%")
            .attr("fill", "black")
            .style("text-anchor", "end")

        // add the tooltip area to the webpage
        let tooltip = d3.select(container).append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)

        this.setScale(data)

        // x-axis
        this.svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis)
          .append("text")
            .attr("class", "label")
            .attr("x", width)
            .attr("fill", "white")
            .attr("y", -6)
            .style("text-anchor", "end")
            .text(data.x.label)

        // y-axis
        this.svg.append("g")
            .attr("class", "y axis")
            .call(yAxis)
          .append("text")
            .attr("class", "label")
            .attr("fill", "white")
            .attr("transform", "rotate(-90)")
            .attr("y", 6)
            .attr("dy", ".71em")
            .style("text-anchor", "end")
            .text(data.y.label);

        // draw dots
        this.svg.selectAll(".dot")
            .data(this.data)
          .enter().append("circle")
            .attr("class", "dot")
            .attr("r", 3.5)
            .attr("cx", xMap)
            .attr("cy", yMap)
            .style("fill", "#fff")
            // .style("fill", function(d) { return color(cValue(d));})
            .on("mouseover", function(d) {
              d3.select(this).transition().duration(100)
              .style("stroke-width", "10")
              .style("stroke", "#0035FF")
              if(container == ".chart-1") {
                let health_height = d[12]*11
                let education_height = d[14]*11
                const SVG_HEIGHT = 507 - 165
                d3.select(".viz-country--initial").text(d[0])
                d3.select(".viz-country--hdi").text(`${d[6]} HDI`)
                d3.select(".viz-country--gdp").text(`${d[19]}% of GDP Spent`)
                self.tl
                .to(self.$refs["health_spending"], .1, {
                  attr: {
                    height: health_height,
                    y: SVG_HEIGHT - health_height
                  },
                  ease: Expo.eastOut
                })
                .to(self.$refs["education_spending"], .1, {
                  attr: {
                    height: education_height,
                    y: SVG_HEIGHT - education_height
                  },
                  ease: Expo.eastOut
                })
                let a = new CountUp(self.$refs["health_spending_text"], 0, d[12], 0, 1, { suffix: "%" })
                a.start()
                let b = new CountUp(self.$refs["education_spending_text"], 0, d[14], 0, 1, { suffix: "%" })
                b.start()
              }
              if(container == ".chart-2") {
                self.activate(d)
                let a = new CountUp(self.$refs["c-health_count"], 0, d[13], 0, 1, { prefix: "$" })
                a.start()
                let b = new CountUp(self.$refs["c-education_count"], 0, d[15], 0, 1, { prefix: "$" })
                b.start()
                let c = new CountUp(self.$refs["viz-population"], 0, d[2])
                c.start()
                d3.select(".viz-country").text(d[0])
                tooltip.transition()
                     .duration(200)
                     .style("opacity", .9)
                tooltip.html(d[0] + "<br/> (" + self.xValue(d)
      	        + ", " + self.yValue(d) + ")")
                     .style("left", (d3.event.pageX) + "px")
                     .style("top", (d3.event.pageY - 200) + "px")
              }
            })
            .on("mouseout", function(d) {
              d3.select(this).transition().duration(300)
              .style("stroke-width", "1")
              .style("stroke", "#000")
              if(container == ".chart-2") {
                self.deactivate()
                tooltip.transition()
                     .duration(500)
                     .style("opacity", 0)
               }
            })
    },
    getData (callback) {
      let self = this
      const API_LOC = 'https://sheets.googleapis.com/v4/spreadsheets'
      const SPREADSHEET_ID = this.spreadsheet_id
      const SHEET_SELECTION = 'Data2!A6:AH200'
      const API_KEY = `?key=${this.api_key}`
      axios.get(`${API_LOC}/${SPREADSHEET_ID}/values/${SHEET_SELECTION}${API_KEY}`)
      .then((response) => {
        self.data = response.data.values
        if (callback) return callback()
      })
      .catch((error) => {
        console.log(error)
      })
    },
    buildCharts () {
      this.buildChart(".chart-1", { x: this.options_1[0], y: this.options_2[3] })
      this.buildChart(".chart-2", { x: this.selected_1, y: this.selected_2 })
      let b = new CountUp(this.$refs["top-10"], 0, 39.5, 1, 3, { suffix: "%", decimal: "." })
      let c = new CountUp(this.$refs["bottom-190"], 0, 33.33, 2, 3, { suffix: "%", decimal: "." })
      let a = new CountUp(this.$refs["difference"], 0, 6.2, 1, 5, { prefix: "+", suffix: "%", decimal: "." })
      b.start()
      c.start()
      a.start()
    }
  },
  created () {
    this.getData(this.buildCharts)
  }
}
