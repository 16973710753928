const INTEREST_CONSTANT = .0301

const DEFAULT_OPTION = {
  strike: 0.5,
  premium: 0.15,
  quantity: 1,
  minimum: 0,
  maximum: 3,
  current: .337,
  expiration: "2024-01-19",
  vol: 154.29,
  id: 0,
  interest: INTEREST_CONSTANT,
  ticker: "NILE"
}

const BlackScholes = (p, strike, daysToExp, vol, interest) => {
  let s = p
  let k = strike
  let t = daysToExp / 365
  let v = vol / 100
  let r = interest
  let callPut = "call"

  let price = null;
  let w = (r * t + Math.pow(v, 2) * t / 2 - Math.log(k / s)) / (v * Math.sqrt(t));
  if (callPut === "call") {
    price = s * stdNormCDF(w) - k * Math.pow(Math.E, -1 * r * t) * stdNormCDF(w - v * Math.sqrt(t));
    return price
   }
   // this is a put
   price = k * Math.pow(Math.E, -1 * r * t) * stdNormCDF(v * Math.sqrt(t) - w) - s * stdNormCDF(-w);
   return price;
}

const doubleFactorial = (n) => {
  var val = 1;
  for(var i = n; i > 1; i-=2)
  {
    val *= i;
  }
  return val;
}

const stdNormCDF = (x) => {
  var probability = 0;
  // avoid divergence in the series which happens around +/-8 when summing the
  // first 100 terms
  if(x >= 8)
  {
    probability = 1;
  }
  else if(x <= -8)
  {
    probability = 0;
  }
  else
  {
    for(var i = 0; i < 100; i++)
    {
      probability += (Math.pow(x, 2*i+1)/doubleFactorial(2*i+1));
    }
    probability *= Math.pow(Math.E, -0.5*Math.pow(x, 2));
    probability /= Math.sqrt(2*Math.PI);
    probability += 0.5;
  }
  return probability;
}

const FindProbability = (price, current, daysToExp, vol) => {
  let result = {}

  let p = price
  let q = current
  let t = daysToExp / 365
  let v = vol / 100

  let vt = v*Math.sqrt(t);
  let lnpq = Math.log(q/p);
  let d1 = lnpq / vt;

  let y = Math.floor(1/(1+.2316419*Math.abs(d1))*100000)/100000;
  let z = Math.floor(.3989423*Math.exp(-((d1*d1)/2))*100000)/100000;
  let y5 = 1.330274*Math.pow(y,5);
  let y4 = 1.821256*Math.pow(y,4);
  let y3 = 1.781478*Math.pow(y,3);
  let y2 = .356538*Math.pow(y,2);
  let y1 = .3193815*y;
  let x = 1-z*(y5-y4+y3-y2+y1);
  x = Math.floor(x*100000)/100000;

  if (d1<0) {x=1-x};

  result.above = Math.floor(x*1000)/10;
  result.below = Math.floor((1-x)*1000)/10;

  return result
}

const DaysToExp = (expiration) => {
  const expirationDate = new Date(expiration)
  const todaysDate = new Date()
  let difference = expirationDate.getTime() - todaysDate.getTime()
  difference = difference / (1000 * 3600 * 24)
  return difference  
}

export {
  BlackScholes,
  FindProbability,
  DaysToExp,
  INTEREST_CONSTANT,
  DEFAULT_OPTION
}

/*
  PutCallFlag: Either "put" or "call"
  S: Stock Price
  X: Strike Price
  T: Time to expiration (in years)
  r: Risk-free rate
  v: Volatility
  This is the same one found in http://www.espenhaug.com/black_scholes.html
  but written with proper indentation and a === instead of == because it's
  faster, and it doesn't declare 5 useless variables (although if you really
  want to do it to have more elegant code I left a commented CND function in
  the end)
*/

//
// const BlackScholes = (PutCallFlag, S, X, T, r, v) => {
//   var d1 = (Math.log(S / X) + (r + v * v / 2) * T) / (v * Math.sqrt(T));
//   var d2 = d1 - v * Math.sqrt(T);
//   if (PutCallFlag === "call") {
//     return ( S * CND(d1)-X * Math.exp(-r * T) * CND(d2) );
//   } else {
//     return ( X * Math.exp(-r * T) * CND(-d2) - S * CND(-d1) );
//   }
// }
//
// /* The cummulative Normal distribution function: */
// const CND = (x) => {
//   if(x < 0) {
//     return ( 1-CND(-x) );
//   } else {
//     k = 1 / (1 + .2316419 * x);
//     return ( 1 - Math.exp(-x * x / 2)/ Math.sqrt(2*Math.PI) * k * (.31938153 + k * (-.356563782 + k * (1.781477937 + k * (-1.821255978 + k * 1.330274429)))) );
//   }
// }
//
// export {
//   BlackScholes
// }


/*
  With the "a" variables
function CND(x){
  var a1 = .31938153,
      a2 = -.356563782,
      a3 = 1.781477937,
      a4 = -1.821255978,
      a5 = 1.330274429;
  if(x<0.0) {
    return 1-CND(-x);
  } else {
    k = 1.0 / (1 + 0.2316419 * x);
    return ( 1 - Math.exp(-x * x / 2)/ Math.sqrt(2*Math.PI) * k * (a1 + k * (a2 + k * (a3 + k * (a4 + k * a5)))) );
  }
}
*/
