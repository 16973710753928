//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { DaysToExp, INTEREST_CONSTANT } from '../../data/helpers'

export default {
  name: 'option-input',
  data () {
    return {
      // info: {}
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    saveOption () {
      this.$emit('save', this.info)
    }
  },
  computed: {
    daysToExp () {
      return DaysToExp(this.expiration)
    },
    info () {
      let obj = Object.assign({}, this.$store.getters.getActiveOption)
      return obj
    },
    heading () {
      return `${this.info.ticker} @ ${this.info.strike}`
    }
  }
}
