//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterCheck from './FilterCheck'

export default {
  name: 'Header',
  components: {
    FilterCheck
  },
  data () {
    return {
      items: [
        {
          title: 'Services',
          param: 'services'
        },
        {
          title: 'Experiments',
          param: 'rnd'
        },
        {
          title: 'Products',
          param: 'ventures'
        }
      ]
    }
  },
  methods: {
    filter(item) {
      this.$store.commit('toggleFilter', item)
      // this.$router.push({query: { ...this.$route.query, sort: item }})
    },
    activeFilter(item) {
      const filters = this.$store.state.filters
      if(!filters.length) return false
      return filters.includes(item)
    }
  },
  mounted() {
    this.activeFilter()
  }
}
