//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { BlackScholes, FindProbability, DaysToExp } from '../../data/helpers'
import moment from 'moment'
const INTEREST_CONSTANT = .0301
const RUN_COUNT = 10
import * as d3 from 'd3'

const COLOR_POSITIVE = `#29FFF1`,
      COLOR_WHITE = `#FFFFFF`,
      COLOR_POSITIVE_SUB = `#1F213C`

const DEFAULT_MAX_ITEM = {
  target: 0
}

Vue.filter("formatLiftNumber", function (value) {
  let v = value;
  let negative = false;
  v = v.toFixed(2)
  if (v < 0) negative = true
  v = v.toString()
  if (negative) v = "↓$" + v.replace("-", "")
  if (!negative) v = "↑$" + v
  v = v.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return v;
});

Vue.filter("formatNumber", function (value) {
  let v = value;
  v = v.toFixed(2)
  v = v.toString()
  v = "$" + v
  v = v.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return v;
});

Vue.filter("formatDate", (val) => {
  if (val) {
    return moment(String(val)).format("MM/DD/YY")
  }
})

export default {
  name: 'soption',
  props: ['info', 'cw', 'winner'],
  data () {
    return {
      list: [],
      svg: {}
    }
  },
  mounted () {
    this.svgInit()
  },
  methods: {
    findProbability (price) {
      return FindProbability(price, this.info.current, this.daysToExp, this.info.vol)
    },
    blackScholes (p) {
      return BlackScholes(p, this.info.strike, this.daysToExp, this.info.vol, INTEREST_CONSTANT)
    },
    activateOption () {
      this.$emit('activate', this.info)
    },
    runIt () {
      const INTERVALS = 500
      let target = 0
      let prob = 0
      let ratio = 0
      let max = this.info.current*20
      let frequency = max / INTERVALS
      this.list = []

      for (let i = 0; i < INTERVALS; i++) {
        target = target + frequency
        prob = this.findProbability(target)
        ratio = this.getEstimateAndConclusion(prob, target)
        if (ratio.data.action) {
          this.list.push({
            target: target,
            estimate: parseFloat(ratio.estimate) / this.shares,
            probability: parseFloat(prob.above),
            ratio: parseFloat(ratio.data.value),
            roi: ((parseFloat(ratio.estimate) / this.cost)).toFixed(1)
          })
        }
      }
      
      this.svg.element.selectAll("*").remove()

      this.svg.x = d3.scaleLinear()
          .domain([0, d3.max(this.list, function(d) { return d.target; })])
          .range([0, this.svg.width]);

      this.svg.y = d3.scaleLinear()
          .domain([
            d3.min(this.list, function(d) { return parseFloat(d.ratio); }),
            d3.max(this.list, function(d) { return parseFloat(d.ratio); })
            ])
          .range([this.svg.height, 0]);
      
      let self = this
      let xAxis = d3.axisBottom(self.svg.x)

      let area1 = d3.area()
          .x(function(d) { return self.svg.x(d.target); })
          .y0(self.svg.height)
          .y1(function(d) { return self.svg.y(d.ratio); });

      this.svg.element.append("path")
          .datum(this.list)
          .attr("class", "area")
          .style("fill", COLOR_POSITIVE_SUB)
          .style("stroke", COLOR_POSITIVE)
          .attr("d", area1);

      this.svg.element.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + self.svg.height + ")")
          .call(xAxis);
      
      this.svg.element.append("text")
          .attr("class", "x label")
          .attr("text-anchor", "end")
          .attr("x", self.svg.width - 6)
          .attr("y", self.svg.height - 6)
          .style("fill", "#FFFFFF")
          .text("Stock Price");

      this.svg.element.append('line').classed('hoverLine', true)
      this.svg.element.append('circle').classed('hoverPoint', true);
      this.svg.element.append("text").classed('hoverText', true);

      this.svg.element.append('rect')
          .attr('fill', 'transparent')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', self.svg.width)
          .attr('height', self.svg.height)
        ;
      
      this.svg.element.on('mousemove', this.d3Mousemove)

    },
    d3Mousemove () {
      d3.event.preventDefault();
      const mouse = d3.mouse(d3.event.target);
      const [
        xCoord,
        yCoord,
      ] = mouse;
      let self = this

      const mousePrice = self.svg.x.invert(xCoord);
      
      // if (this.svg.x(mouseDateSnap) < this.svg.margin.left ||
      //   this.svg.x(mouseDateSnap) > width - this.svg.margin.right) {
      //   return;
      // }


      const bisectTarget = d3.bisector(d => d.target).right;
      const xIndex = bisectTarget(this.list, mousePrice, 1);

      const mouseRatio = this.list[xIndex].ratio;

      self.svg.element.selectAll('.hoverLine')
        .attr('x1', self.svg.x(mousePrice))
        .attr('y1', 0)
        .attr('x2', self.svg.x(mousePrice))
        .attr('y2', self.svg.height)
        .attr('stroke', `#204442`)
        .attr('fill', `#204442`)
      ;

      self.svg.element.selectAll('.hoverPoint')
        .attr('cx', self.svg.x(mousePrice))
        .attr('cy', self.svg.y(mouseRatio))
        .attr('r', '7')
        .attr('fill', COLOR_POSITIVE)
      ;
      
      const isLessThanHalf = xIndex > this.list.length / 2;
      const hoverTextX = isLessThanHalf ? '-0.75em' : '0.75em';
      const hoverTextAnchor = isLessThanHalf ? 'end' : 'start';

      self.svg.element.selectAll('.hoverText')
        .attr('x', self.svg.x(mousePrice))
        .attr('y', self.svg.y(mouseRatio))
        .attr('dx', hoverTextX)
        .attr('dy', 0)
        .style('text-anchor', hoverTextAnchor)
        .text(`↑${mouseRatio.toFixed(2)}%, $${mousePrice.toFixed(2)}`)
        .style('fill', COLOR_WHITE)
  
    },
    getRatioConclusion (probability, ratio, option) {
      let result = {}
      // result.value = ((probability.above / ratio) - 1)*100
      result.value = probability.above - ratio
      result.action = probability.above > ratio
      // If there's an option passed, update it and return result
      if (option) return this.updateOptionAndReturnResult(option, result)
      return result
    },
    updateOptionAndReturnResult (option, result) {
      let newOption = option
      newOption.result = result
      return result
    },
    getEstimateAndConclusion (probability, price) {
      let optionAtMax = this.blackScholes(price)
      let betToGain = optionAtMax * this.shares
      let betRatio = this.getBetRatio(this.cost, betToGain)
      let rat = this.getRatioConclusion(probability, betRatio) 
      return {
        data: rat,
        estimate: betToGain
      }
    },
    svgInit () {
      this.svg.margin = { top: 20, right: 20, bottom: 20, left: 20 }
      this.svg.width = 338 - this.svg.margin.left - this.svg.margin.right,
      this.svg.height = 200 - this.svg.margin.top - this.svg.margin.bottom

      this.svg.element = d3.select(`#chart-${this.info.id}`)
          .attr("width", this.svg.width + this.svg.margin.left + this.svg.margin.right)
          .attr("height", this.svg.height + this.svg.margin.top + this.svg.margin.bottom)
          .append("g")
          .attr("transform", "translate(" + this.svg.margin.left + "," + this.svg.margin.top + ")")
    },
    getBetRatio (cost, betToGain) {
      return (cost / betToGain)*100
    },
    getShareCount (quantity) {
      return quantity * 100
    },
    getCost (shareCount, premium) {
      return shareCount * premium
    },
    setMaxAtMax () {
      if (!this.maxItem) return
      this.info.maximum = this.maxItem.target
    }
  },
  computed: {
    daysToExp () {
      return DaysToExp(this.info.expiration)
    },
    shares () {
      return this.getShareCount(this.info.quantity)
    },
    cost () {
      return this.getCost(this.shares, this.info.premium)
    },
    probs () {
      return this.findProbability(this.info.strike)
    },
    optionEstimate () {
      return (this.blackScholes(this.info.current)*this.shares)
    },
    priceLift () {
      // TODO convert this to $ + up down arrow
      return this.cost - this.optionEstimate
    },
    probsOfMax () {
      return this.findProbability(this.info.maximum)
    },
    optionAtMax () {
      return this.blackScholes(this.info.maximum)
    },
    maxSale () {
      return ((this.optionAtMax * this.shares).toFixed(2))
    },
    maxProfit () {
      return (this.maxSale - this.cost)
    },
    betToGain () {
      return this.optionAtMax * this.shares
    },
    betRatio () {
      return this.getBetRatio(this.cost, this.betToGain)
    },
    ratioConclusion () {
      return this.getRatioConclusion(this.probsOfMax, this.betRatio, this.info)
    },
    maxItem () {
      let obj = _.maxBy(this.list, function(o) { return o.ratio})
      return obj || DEFAULT_MAX_ITEM
    }
  },
  watch: {
    info: {
      handler (val) {
        console.log("update")
        this.runIt()
      },
      deep: true
    }
  }
}
