//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FilterCheck',
  props: [
    'item',
    'status'
  ],
  data () {
    return {
    }
  },
  methods: {
    filter() {
      this.$store.commit('navigated')
      if (this.item.param) {
        this.$ma.trackEvent({action: 'Selected Category', properties: {'Filter': this.item.title}}, ['ga'])
        if (this.status === 'partial') {
          return this.$store.commit('categoryOn', this.item.param)
        }
        return this.$store.commit('toggleCategory', this.item.param)
      }
      if (this.item.type) {
        this.$ma.trackEvent({action: 'Selected Filter', properties: {'Filter': this.item.title}}, ['ga'])
        return this.$store.commit('toggleFilter', this.item)
      }
      // let query = this.$store.state.filters.join(',')
      // this.$router.push({ query: { sort: query } })
    }
  },
  computed: {
    activeFilter() {
      if (this.item.type) {
        return this.$store.state.filters[this.item.type][this.item.id].active
      }
    }
  }
}
