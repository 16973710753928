//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Item from './Children/Item.vue'
import Author from './Shared/Author.vue'
import Sidebar from './Shared/Sidebar.vue'
import Foot from './Shared/Foot.vue'
import _ from 'lodash'
import data from '../data/data'

export default {
  name: 'Home',
  components: {
    Item,
    Author,
    Foot,
    Sidebar
  },
  data () {
    return {
      feed: [],
      maxZ: 1
    }
  },
  methods: {
    updateZ (z) {
      this.maxZ = z
    }
  },
  computed: {
    filteredItems() {
      return _.filter(this.feed, function(item) {
        return item.active
      })
    },
    noContent() {
      return this.feed.length === 0
    }
  },
  created() {
    this.feed = data
    this.$store.commit('populateFilters')
    this.$ma.trackEvent({action: 'Visited Home'}, ['ga'])

    // let query = this.$store.state.filters.join(',')
    // this.$router.push({ query: { sort: query } }).catch( err => {})
  }
}
