//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import axios from 'axios'
import Vue from 'vue'
const API = 'http://localhost:3000/api'

Vue.filter("formatNumber", function (value) {
  let v = value;
  v = v.toFixed(2)
  return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("formatChange", function (value) {
  let v = value;
  v = v.toFixed(2)
  if (v > 0) return `+${v}%`
  return `${v}%`
});

export default {
  name: 'Crypto',
  data () {
    return {
      cryptos: {},
      c: {},
      maxCap: 25000000,
      minCap: 1,
      cryptoType: 'coins',
      cryptoSymbols: this.getSymbols,
      cryptoInfo: {},
      showTags: false,
      cryptoTypes: {
        'all': {
          marker: 'all',
          text: 'All'
        },
        'coins': {
          marker: 'coins',
          text: 'Coins'
        },
        'tokens': {
          marker: 'tokens',
          text: 'Tokens'
        }
      },
      loading: false,
      change_marker: 'percent_change_7d',
      current_sort: 'desc',
      scrolled: false,
      lastKnownScrollPosition: 0,
      ticking: false,
      sorts: {
        "desc": {
          "marker": "desc",
          "text": "↑ MC",
          "tooltip": "Market Cap"
        },
        "asc": {
          "marker": "asc",
          "text": "↓ MC",
          "tooltip": "Market Cap"
        },
        "pChange": {
          "marker": "pChange",
          "text": "↑ Change",
          "tooltip": "Market Cap"
        },
        "nChange": {
          "marker": "nChange",
          "text": "↓ Change",
          "tooltip": "Market Cap"
        }
      },
      pChanges: {
        "1H": {
          marker: 'percent_change_1h',
          text: "1H"
        },
        "7D": {
          marker: 'percent_change_7d',
          text: "7D"
        },
        "24H": {
          marker: 'percent_change_24h',
          text: "24H"
        },
        "30D": {
          marker: 'percent_change_30d',
          text: "30D"
        },
        "60D": {
          marker: 'percent_change_60d',
          text: "60D"
        },
        "90D": {
          marker: 'percent_change_90d',
          text: "90D"
        }
      }
    }
  },
  methods: {
    logKey (e) {
      if (e.key === "t") return this.toggleTags()
    },
    percentChange (marker) {
      this.change_marker = marker
    },
    toggleTags () {
      this.showTags = !this.showTags
      return
    },
    load () {
      this.loading = true
      axios.get(`${API}/latest?max=${this.maxCap}&min=${this.minCap}&type=${this.cryptoType}`).then((res) => {
        this.cryptos = res.data.data
        this.c = this.descMCCoins
        this.loading = false
      })
      this.getCryptos()
    },
    sortList (s) {
      this.current_sort = s
      if (s === 'asc') return this.c = this.ascMCCoins
      if (s === 'desc') return this.c = this.descMCCoins
      if (s === 'pChange') return this.c = this.posChangeCoins
      if (s === 'nChange') return this.c = this.negChangeCoins
    },
    filterType (f) {
      this.cryptoType = f
    },
    getCryptos () {
      axios.get(`${API}/cryptos`).then((res) => {
        _.each(res.data, (crypto) => {
          this.cryptoInfo[`${crypto._id}`] = crypto
        })
      })
      console.log(this.cryptoInfo)
    },
    updateCrypto (symbol, body) {
      axios.put(`${API}/cryptos`, body).then((res) => {
        this.cryptoInfo[`${symbol}`] = res.data
        this.$forceUpdate()
      })
    },
    favoriteCrypto (symbol) {
      this.updateCrypto(symbol, {
        favorite: true,
        symbol: symbol
      });
    },
    toggleCrypto (symbol, crypto) {
      this.updateCrypto(symbol, {
        favorite: !crypto.favorite,
        symbol: symbol
      });
    }
   },
  computed: {
    descMCCoins () {
      return _.orderBy(this.cryptos, 'quote.USD.market_cap', 'desc')
    },
    ascMCCoins () {
      return _.orderBy(this.cryptos, 'quote.USD.market_cap', 'asc')
    },
    posChangeCoins () {
      return _.orderBy(this.cryptos, `quote.USD[${this.change_marker}]`, 'desc')
    },
    negChangeCoins () {
      return _.orderBy(this.cryptos, `quote.USD[${this.change_marker}]`, 'asc')
    },
  },
  mounted () {
    document.addEventListener('keyup', this.logKey);
    this.load()
  }
}
