//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CategoryFilter from './CategoryFilter'

export default {
  name: 'Sidebar',
  components: {
    CategoryFilter
  },
  data () {
    return {
      logoNo: false,
      mobileActive: false,
      items: [
        {
          title: 'Products',
          param: 'ventures',
          active: true
        },
        {
          title: 'Experiments',
          param: 'rnd',
          active: true
        },
        {
          title: 'Services',
          param: 'services',
          active: true
        },

        {
          title: 'Content',
          param: 'content',
          active: true
        }
      ]
    }
  },
  methods: {
    toggleLogo () {
      // this.logoNo = !this.logoNo;
    },
    toggleNav () {
      this.mobileActive = !this.mobileActive
    }
  }
}
