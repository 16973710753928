import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from './store'
import Headful from 'vue-headful'
import VueCircleSlider from 'vue-circle-slider'
import VueDragDrop from 'vue-drag-drop'
import VueSelect from 'vue-select'
import VTooltip from 'v-tooltip'
import Storage from 'vue-ls'
import VueDraggableResizable from 'vue-draggable-resizable'
import Vue2TouchEvents from 'vue2-touch-events'

import Home from './components/Home'
import WorkItem from './components/WorkItem'
// import Music from './components/Music'
import OS from './components/OS'
import Options from './components/Options/Options'
import Crypto from './components/Crypto'
import TimeLeft from './components/TimeLeft'
import Info from './components/Info'
import Fantasy from './components/Fantasy'
import DataIsB from './components/DataIsB'
import DataVisualizer from './components/DataVisualizer'
import StateMachine from './components/StateMachine'


import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.use(VTooltip)
Vue.use(Vue2TouchEvents)

Vue.use(Storage, {
  namespace: 'subtract_'
})
Vue.use(VueDragDrop)
Vue.use(VueCircleSlider)
Vue.use(VueRouter)
Vue.component('vue-headful', Headful)
Vue.component('v-select', VueSelect)

import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, 'ws://localhost:9090', {
  connectManually: true,
})
Vue.config.productionTip = false

import VueMultianalytics from 'vue-multianalytics'

let mixpanelConfig = {
  token: '0b6f66ed161761532c6bc3f65a2e35fe'
}

let gaConfig = {
  appName: 'subtract.design', // Mandatory
  appVersion: '0.1', // Mandatory
  trackingId: 'UA-122808129-1', // Mandatory
  debug: false, // Whether or not display console logs debugs (optional)
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home',
      meta: {
        analytics: 'Home'
      }
    },
    // {
    //   path: '/anna-machine-learning-drum-bot/',
    //   component: Music,
    //   name: 'music',
    //   meta: {
    //     analytics: 'Music'
    //   }
    // },
    {
      path: '/state/',
      component: StateMachine,
      name: 'state-machine',
      meta: {
        analytics: 'State Machine'
      }
    },
    {
      path: '/crypto/',
      component: Crypto,
      name: 'crypto',
      meta: {
        analytics: 'Crypto'
      }
    },
    {
      path: '/daily-blocks/',
      component: OS,
      name: 'os',
      meta: {
        analytics: 'Daily Blocks'
      }
    },
    {
      path: '/options/',
      component: Options,
      name: 'options',
      meta: {
        analytics: 'Options'
      }
    },
    {
      path: '/timeleft/',
      component: TimeLeft,
      name: 'timeleft',
      meta: {
        analytics: 'Time Left'
      }
    },
    {
      path: '/info',
      redirect: '/work'
    },
    {
      path: '/work',
      component: Info,
      name: 'Info',
      meta: {
        analytics: 'Info'
      }
    },
    {
      path: '/fantasy',
      component: Fantasy,
      name: 'Fantasy',
      meta: {
        analytics: 'Fantasy'
      }
    },
    {
      path: '/viz',
      component: DataIsB,
      name: 'viz',
      meta: {
        analytics: 'DataIsB'
      }
    },
    {
      path: '/data',
      component: DataVisualizer,
      name: 'data',
      meta: {
        analytics: 'Data Visualizer'
      }
    },
    {
      path: '/w/:id',
      component: WorkItem,
      name: 'work',
      meta: {
        analytics: 'Work'
      }
    },
  ]
})

Vue.use(VueMultianalytics, {
  modules: {
    mixpanel: mixpanelConfig,
    ga: gaConfig
  },
  routing: {
    vueRouter: router, //  Pass the router instance to automatically sync with router (optional)
    preferredProperty: 'page' // By default 'path' and related with vueRouter (optional)
  }
})
//
// router.afterEach(function () {
//   window.scrollTo(0, 0)
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
