//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Foot',
  data () {
    return {
      items: [
        {
          title: 'Twitter',
          param: '//twitter.com/thomasdrach'
        },
        {
          title: 'Email',
          param: this.buildEmail()
        },
        {
          title: 'Github',
          param: '//github.com/tdrach'
        }
      ]
    }
  },
  methods: {
    buildEmail() {
      return 'mailto:thomas@subtract.design'
    }
  }
}
