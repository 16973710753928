//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import work from '../data/work'

export default {
  name: 'work-item',
  props: [
  ],
  data() {
    return {
      work: work[this.$route.params.id]
    }
  },
  mounted () {
  },
  methods: {
    getImage (img) {
      let image = require.context('../assets/images/clients/_studies/', false,  /\.(jpg|png|svg)$/)
      return image(`./${img}`)
    }
  },
  computed: {
  }
}
