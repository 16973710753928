//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'

export default {
  name: 'item',
  props: [
    'data',
    'index',
    'maxZ'
  ],
  data() {
    return {
      active: false,
      hover: false,
      kapow: false,
      minHeight: 200,
      dragging: false,
      currentX: null,
      currentY: null,
      waiting: true,
      x: 0,
      y: 0
    }
  },
  created () {
    this.x = this.setX()
    this.y = this.setY()
  },
  methods: {
    activateHover() {
      this.hover = true
      this.active = true
      this.$store.commit('activateHover')
      this.minHeight = this.$el.offsetHeight
    },
    deActivateHover() {
      this.active = false
      this.hover = false
      this.$store.commit('deActivateHover')
    },
    dragStop (x,y) {
      return setTimeout(()=> {
        this.dragging = false
      }, 100)
    },
    navigate (link) {
      if (this.dragging) return
      this.$ma.trackEvent({action: 'Link Clicked', properties: { origin: this.data.title}}, ['ga'])
      window.open(link, '_blank')
    },
    getImage (img) {
      var image = require.context('../../assets/images/', false, /\.svg$/)
      return image('./' + img + ".svg")
    },
    onDrag (x,y) {
      this.$ma.trackEvent({action: 'Dragged', properties: { title: this.data.title }}, ['ga'])
      this.dragging = true
    },
    onDragStart (e) {
      return this.bumpZ()
    },
    bumpZ () {
      this.$el.style.zIndex = this.maxZ + 1
      this.$emit('updateZ', this.$el.style.zIndex)
    },
    boom () {
      this.kapow = true
      setTimeout(()=> {
        this.kapow = false
      }, 500)
    },
    sparringGetInTouch () {
      this.$ma.trackEvent({action: 'Contact Clicked', properties: { origin: 'Sparring Sessions'}}, ['ga'])
      window.location = 'mailto:thomas@subtract.design'
    },
    setX () {
      this.waiting = false
      if (this.index === 1990) return window.innerWidth/7.5
      let w = window.innerWidth/10
      if (this.index === 3) return w
      if (this.index === 4) return w * 3
      if (this.index === 6) return w * 4
      if (this.index === 7) return w * 2
      return this.index * w
    },
    setY () {
      this.waiting = false
      if (this.index === 1990) return window.innerHeight/4
      let h = window.innerHeight/10
      if (this.index === 0) return h * 2
      if (this.index === 2) return h * 5
      if (this.index === 3) return h * 6
      if (this.index === 4) return h * 0.5
      if (this.index === 6) return h * 2
      if (this.index === 7) return h * 8
      return this.index * h
    }
  },
  computed: {
    className() {
      if (!this.data.type) return `is-major`
      if (this.data.class) return this.data.class
      return `is-${this.data.type}`
    }
  }
}
