//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import Vue from 'vue'
import Soption from './Soption'
import OptionInput from './OptionInput'
import axios from 'axios'
import { DEFAULT_OPTION } from '../../data/helpers';
import Axios from 'axios';

Vue.filter("formatValue", function (value) {
  let v = value;
  v = v.toFixed(2)
  v = v.toString()
  v = "↑" + v + "%"
  return v;
});

Vue.filter("formatCurrency", function (value) {
  if (!value) return
  let v = parseFloat(value);
  v = v.toFixed(2)
  v = v.toString()
  v = "$" + v
  v = v.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return v;
});


export default {
  name: 'Options',
  components: { Soption, OptionInput },
  data () {
    return {
      showModal: false,
      authed: false,
      ticker: "ESTE",
      winningValue: 0
    }
  },
  methods: {
    addOption () {
      this.$store.commit('addAndActivate')
      this.showModal = true
    },
    activateOption (option) {
      this.$store.commit('activateOption', option)
      this.showModal = true
    },
    close () {
      this.showModal = false
    },
    saveOption (option) {
      this.$store.commit('editOption', option)
      this.$store.commit('activateOption', option)
      this.$forceUpdate();
    },
    getInsiderData () {

    },
    authData () {
      this.$socket.send(JSON.stringify({"action": "auth", "key": "AKJO68REBG1O8229BS7Q", "secret": "WnLTy477L6JMqUHgrfmpDVufPjEZM8kLh4isxoHt"}))
    },
    subscribe () {
      this.authed = true
      this.$socket.send(JSON.stringify({"action":"subscribe","trades":["AAPL"],"quotes":["AMD","CLDR"],"bars":["AAPL"]}))
    },
    getData () {
      const vm = new Vue()
      vm.$connect('ws://stream.data.alpaca.markets/v2/iex', { format: 'json' })
      vm.$options.sockets.onmessage = (data) => {
        console.log(data.data)
        let d = JSON.parse(data.data)
        if (d[0].T === "success" && d[0].msg === "connected" && !this.authed) return this.authData()
        if (d[0].T === "success" && d[0].msg === "authenticated") {
          this.subscribe()
        }
      }
    },
    disconnect () {
      // need VM
      // vm.$disconnect()
    }
  },
  computed: {
    options () {
      return this.$store.getters.options
    },
    winners () {
      return _.filter(this.options, (o) => {
        if (!o.result) return
        return o.result.value > 0
      })
    },
  },
  mounted () {
    this.$store.commit('addAndActivate')
    this.getInsiderData()
    // this.authData()
    // this.getData()
  },
  watch: {
    winners () {
      let values = []
      for (let key in this.winners) {
        if (!this.winners[key].result) return
        values.push(this.winners[key].result.value)
      }
      this.winningValue = Math.max(...values)
    }
  }
}
