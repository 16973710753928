//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HoverEffect from '../helpers/HoverEffect';
import _ from 'lodash'
import Author from './Shared/Author.vue'
import Foot from './Shared/Foot.vue'
import WorkListItem from './Children/WorkListItem.vue'
import work from '../data/work'

export default {
  name: 'Home',
  components: {
    Author,
    Foot,
    WorkListItem
  },
  data () {
    return {
      work: work,
      tags: [
        'All',
        'Product',
        'Founder',
        'Branding',
      ],
      activeTag: 'All',
      activeImage: null,
      ani: null
    }
  },
  computed: {
    buildEmail() {
      return 'mailto:thomas@subtract.design'
    },
    filteredWork () {
      if (this.activeTag == 'All') return this.work
      return _.filter(this.work, (item) => {
        return item.tags && _.includes(item.tags, this.activeTag)
      })
    }
  },
  methods: {
    activateTag (tag) {
      this.activeTag = tag
    },
    activateItem (event, item, id) {
      let originalImage = this.activeImage
      if (!originalImage) {
        originalImage = require('../assets/images/clients/cleverstack/cleverstack.png')
      }
      this.activeImage = require(`../assets/images/clients/${id}/${id}.png`)
      this.ani.imageUpdate(originalImage, this.activeImage)
      this.ani.next()
    },
    deactivateItem () {
      // this.activeImage = null
    },
    createAnimation () {
      this.ani = new HoverEffect({
        parent: this.$refs.parent,
        bg: this.$refs.bg,
        speedIn: .3,
        speedOut: .65,
        easing: 'Quad.easeOut',
        imagesRatio: 1020/716,
        intensity1: 0.03,
        intensity2: 0.05,
        // angle2: Math.PI / 2,
        hover: false,
        image1: require('../assets/images/clients/cleverstack/cleverstack.png'),
        image2: require('../assets/images/clients/lookwell/lookwell.png'),
        triggers: document.getElementsByClassName("wli"),
        displacementImage: require('../assets/images/dis-diag.png'),
      })
    }
  },
  mounted () {
    this.$store.commit('deActivateHover')
    this.$ma.trackEvent({action: 'Page View', properties: {'page': 'info'}}, ['ga'])
    this.createAnimation()
  }
}
